import cn from "clsx";
import { Alignment } from "design-system/types/types";
import { useEffect, useState } from "react";
import { loadScript } from "design-system/hooks/use-script";

declare global {
  interface Window {
    loadFormAssemblyFormHeadAndBodyContents?: () => void;
  }
}

export interface FormAssemblyEmbedProps {
  embedSourceUrl: string;
  align?: Alignment;
}

export function FormAssemblyEmbed({
  embedSourceUrl,
  align = "center",
}: FormAssemblyEmbedProps) {
  const [formId, setFormId] = useState<string | null>(null);

  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--form-assembly-form-embed",
  );

  useEffect(() => {
    // Extract the form ID using regex
    const formIdMatch = embedSourceUrl.match(/\/publish\/(\d+)/);
    const extractedFormId = formIdMatch ? formIdMatch[1] : null;

    if (extractedFormId) {
      setFormId(extractedFormId);
      loadScript(embedSourceUrl, {
        dataQpTargetId: `fa-form-${extractedFormId}`,
        defer: true,
        onload: () => {
          if (window.loadFormAssemblyFormHeadAndBodyContents) {
            window.loadFormAssemblyFormHeadAndBodyContents();
          }
        },
      });
    } else {
      console.warn("Form ID not found in the URL");
    }
  }, [embedSourceUrl]);

  return (
    <div className={wrapperClass} data-region="cb__form-assembly-form-embed">
      <div className="hbs-form-assembly-form-embed">
        {formId && <div id={`fa-form-${formId}`}></div>}
      </div>
    </div>
  );
}
