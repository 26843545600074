import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { FormAssemblyEmbedEntry } from "frontend/contentful/schema/blocks";
import { FormAssemblyEmbed } from "design-system/components/blocks/form-assembly-embed/form-assembly-embed";

export const ContentfulFormAssemblyEmbed =
  createContentfulComponent<FormAssemblyEmbedEntry>(({ entry }) => {
    if (!entry.fields.embedSourceUrl) {
      return null;
    }

    return (
      <FormAssemblyEmbed
        align={entry.fields.alignment}
        embedSourceUrl={entry.fields.embedSourceUrl}
      />
    );
  });
